import React, {PureComponent} from 'react'
import {faDownload, faInfoCircle, faPaste, faRedo, faSearch, faStepForward} from '@fortawesome/free-solid-svg-icons'
import {Button, Grid, Icon, Message, Modal, Pagination, Segment} from 'semantic-ui-react'
import CustomButton from './CustomButton'
import {json} from "../assets/json";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Spinner from "./Spinner";
import {data} from "./Data";
import pdf from "../assets/Demo Housing.pdf";

class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: this.props.pageNumber,
            data: data,
            downloading: "",
            time: Date.now(),
            loadingEntries: true,
            currentId: '',
            json: '',
            activeIndex: "",
            activeErrorIndex:"",
            openRetryModal: false,
            openRequestModal: false,
        }
    }

    openList = (index) => {
        (this.state.activeErrorIndex === index ? this.setState({activeErrorIndex: null}) : this.setState(
            {activeErrorIndex: index}))
    };

    fetchConfig() {
        setTimeout(() => {
            this.setState({loadingEntries: false});
        }, 2000)
    }


    //Determine if list should be red green or neutral color;
    renderColor = (item) => {
        if (item.skipped) return "inactive";
        else if (!item.thrownUploadException && !item.thrownConfigurationException && item.duration !== 0) return 'positive';
        else if (item.thrownUploadException || item.thrownConfigurationException) return 'negative';
        else return ''
    };

    showSkipModal = (index) => {
        this.setState({openSkipModal: true, activeIndex: index})
    };

    showRetryModal = (index) => {
        this.setState({openRetryModal: true, activeIndex: index})
    };

    showRequestModal = (index, configuration) => {
        this.setState({openRequestModal: true, activeIndex:index}, () => {
            this.setState({json: JSON.stringify(configuration)})
        });
    };

    closeModal = () => {
        this.setState({openSkipModal: false, openRetryModal: false, openRequestModal: false})
    };

    download = () => {
        const link = document.createElement('a');
        link.href = pdf;
        link.target="_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    updateData = (index, action, value) => {
        this.setState({activeIndex: index});
        let array = [];
        for (let i = 0; i < data.length; i++) {
            if (index === i) {
                data[i][action] = value;
            }
            array.push(data[i])
        }
        this.setState({data: array})
        this.setState({openSkipModal: false, openRetryModal: false,})
    };


    componentDidMount() {
        this.fetchConfig();
    }


    render() {
        const {
            openRetryModal, openSkipModal, openRequestModal, activeIndex, loadingEntries, activeErrorIndex
        } = this.state;

        return (
            <div className="dashboard-container">
                <Segment>
                    <Button primary style={{margin: '0.5em'}} onClick={() => this.fetchConfig()}> REFRESH (Last
                        refreshed {new Date(this.state.time).toISOString().slice(11, -1)}) </Button>
                    <Grid.Column>
                        <Pagination
                            activePage={1}
                            totalPages={1}
                            showEllipsis={true}
                        />
                    </Grid.Column>
                </Segment>
                <table className="dashboard-table">
                    <thead className="dashboard-header">
                    <tr>
                        <th>ID</th>
                        <th>Project Name</th>
                        <th>Duration</th>
                        <th>Request Date</th>
                        <th>Request</th>
                        <th>Exports</th>
                        <th>Actions</th>
                        <th>Configuration Status</th>
                        <th>Upload Status</th>
                        <th>Source</th>
                    </tr>
                    </thead>
                    {loadingEntries ?
                        (<tbody>
                            <tr>
                                <td colSpan={10}><Spinner/></td>
                            </tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {this.state.data.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <tr className={`dashboard-list-wrapper ${this.renderColor(item)}`}>
                                            {/*Show disabled ID if item is skipped*/}
                                            <td className="inactive">
                                                <p>{item.id}</p>
                                            </td>
                                            <td>
                                                <p>{item.projectName}</p>
                                            </td>
                                            <td>
                                                {item.exceptionThrown ?
                                                    <p>
                                                        <Icon name='attention'/>
                                                        {item.duration}
                                                    </p>
                                                    :
                                                    <p>
                                                        {item.duration}
                                                    </p>}
                                            </td>
                                            <td>
                                                <p>{item.created}</p>
                                            </td>
                                            <td>
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton buttonType="regular" onClick={() => {
                                                        this.showRequestModal(index,json);
                                                    }} icon={faSearch} disabled={item.skipped}/>
                                                    <CopyToClipboard text={item.options}
                                                                     onCopy={() => this.setState({copied: true})}>
                                                        <CustomButton buttonType="regular" icon={faPaste}
                                                                      disabled={item.skipped}/>
                                                    </CopyToClipboard>
                                                </div>
                                            </td>
                                            <td>
                                                <CustomButton onClick={() => {
                                                    this.download()
                                                }} popup="Download configuration" buttonType="positive"
                                                              disabled={item.skipped}
                                                              icon={faDownload}/>
                                            </td>
                                            <td>
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton
                                                        onClick={() => {
                                                            this.showSkipModal(index)
                                                        }}
                                                        popup="Skip this entry" buttonType="caution"
                                                        icon={faStepForward} disabled={item.skipped}/>
                                                    <CustomButton
                                                        onClick={() => {this.showRetryModal(index)}}
                                                        buttonType="default" icon={faRedo}/>
                                                </div>
                                            </td>
                                            <td>
                                                {item.thrownConfigurationException &&
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton
                                                        disabled={item.skipped}
                                                        onClick={() => {
                                                            this.openList(index)
                                                        }} buttonType="negative" icon={faInfoCircle}/>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                {item.thrownUploadException &&
                                                <div className="dashboard-button-wrapper">
                                                    <CustomButton
                                                                  buttonType="negative"
                                                                  disabled={item.skipped}
                                                                  onClick={() => {
                                                                      this.openList(index)
                                                                  }}
                                                                  icon={faInfoCircle}/>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                <p>{item.source}</p>
                                            </td>
                                        </tr>
                                        <tr className={`dashboard-table-message-wrapper ${this.renderColor(item)} ${activeErrorIndex ===
                                        index ? 'open' : ''}`}>
                                            <td colSpan={10}>
                                                <Message negative>
                                                     <li>ASRR.exe error. Missing ASRR file.</li>
                                                    <li>The selected configuration does not contain a revit file</li>
                                                     <li>ERROR</li>
                                                </Message>
                                            </td>
                                        </tr>

                                        <Modal
                                            size={'mini'}
                                            open={index === activeIndex ? openRetryModal : null}
                                            onClose={this.closeRetryModal}>
                                            <Modal.Content>
                                                <p> Are you sure you want to retry this configuration ?</p>
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button negative onClick={
                                                    this.closeModal}>No</Button>
                                                <Button positive icon='checkmark' labelPosition='right' content='Yes'
                                                        onClick={() => this.updateData(index, "skipped", false)}/>
                                            </Modal.Actions>
                                        </Modal>


                                        <Modal size={'mini'} open={index === activeIndex ? openSkipModal : null}
                                               onClose={this.closeSkipModal}>
                                            <Modal.Content>
                                                <p>"Are you sure you want to skip this configuration ?"</p>
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button negative onClick={
                                                    this.closeModal}>No</Button>
                                                <Button positive icon='checkmark' labelPosition='right' content='Yes'
                                                        onClick={() => this.updateData(index, "skipped", true)
                                                        }/>
                                            </Modal.Actions>
                                        </Modal>

                                        <Modal size={'large'} open={index === activeIndex ? openRequestModal : null}
                                               onClose={this.closeModal}>
                                            <Modal.Content>
                                                <h2>JSON format of Configuration</h2>
                                                <p>{this.state.json}</p>
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button negative onClick={this.closeModal}>Close</Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </React.Fragment>
                                )
                            })}
                            </tbody>)}
                </table>
                <Segment>
                    <Button primary style={{margin: '0.5em'}} onClick={() => this.fetchConfig()}> REFRESH (Last
                        refreshed {new Date(this.state.time).toISOString().slice(11, -1)}) </Button>
                    <Grid.Column>
                        <Pagination
                            activePage={1}
                            totalPages={1}
                        />
                    </Grid.Column>
                </Segment>
            </div>
        )
    }
}

export default Home
