import React, {Component} from 'react'
import {Button, Checkbox, Divider, Form, Grid, Header, Icon, Image, Progress, Segment} from 'semantic-ui-react'
import logo from '../Images/asrr.svg'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'

class Import extends Component {
    constructor(props) {
        super(props);
        this.fileReader = new FileReader();
        this.state = {
            percent: 0,
            submitted: false,
            requestId: '',
            status: '',
            show: false,
            data: [],
            dataLoaded: false,
            finished: false
        };
    }

    download = () => {
        this.setState({submitted: true});
        setInterval(() => {
            if (this.state.percent < 100) {
                this.setState({
                    percent: this.state.percent + 1
                });
                console.log(this.state.percent);
            } else {
                this.setState({finished: true})
            }
        }, 50)
    };

    render() {
        return (
            <div className="white">
                <Grid verticalAlign='middle' stackable textAlign='center'>
                    <Grid.Row style={{height: '60vh'}}>
                        <Grid.Column style={{maxWidth: '800px', marginTop: "20px"}}>
                            <Segment className="drop-shadow">
                                <Image size="small" src={logo} style={{margin: 'auto'}}/>
                                <Divider/>
                                <Segment placeholder>
                                    <Header as='h4' icon>
                                        <Icon name='file outline'/>
                                        {this.state.finished ? "File successfully uploaded. Go to dashboard to view entry." : " demo_application.json"}
                                    </Header>
                                    <Button disabled={this.state.finished} onClick={() => this.download()} secondary
                                            className="add-file-button">Click me</Button>
                                </Segment>
                                <Divider/>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Checkbox disabled label='.rvt file' defaultChecked/>
                                        </Form.Field>
                                        <Form.Field>
                                            <Checkbox defaultChecked disabled label='.pdf file'/>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                                <div style={{display: this.state.percent === 0 ? 'none' : ''}}>
                                    <Divider/>
                                    <Progress percent={this.state.percent} label={this.state.status} indicating/>
                                    <Divider/>
                                </div>

                                <Message hidden={!this.state.submitted}>Configuration submitted with
                                    requestId <b>{"ASRR-7"}</b> </Message>
                                <Message hidden={!this.state.submitted} icon>
                                    <Message.Content>
                                        <Message.Header>Configuration stats</Message.Header>
                                        <li>This configuration contains 3 blocks</li>
                                        <li>Each block has a minimum of 12 houses</li>
                                    </Message.Content>
                                </Message>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default Import
