export const json = {
  "projectName": "ASRR-Demo-Application",
  "source": "ASRR",
  "type": "project",
  "configuration": {
    "logo": "ASRR",
    "contact": "ASRR Tech",
    "blocks": [
      {
        "name": "ASRR Tech",
        "position": {
          "x": 4000,
          "y": 0,
          "z": 6000
        },
        "rotation": {
          "x": 0.3,
          "y": 0.7,
          "z": 0
        },
        "houses": [
          {
            "name": "Dakterras Rechts",
            "houseNumber": 15,
            "type": "Type-A",
            "position": {
              "x": 280,
              "y": 0,
              "z": 0
            },
            "rotation": {
              "x": 1,
              "y": 0,
              "z": 0
            },
            "configOptions": {
              "plaatsing": "rechts",
              "beukmaat": "2200",
              "woningdiepte": "5440",
              "daktype": "langskap"
            }
          },
          {
            "name": "Dakterras Links",
            "houseNumber": 16,
            "type": "Type-Z",
            "position": {
              "x": 5680,
              "y": 0,
              "z": 0
            },
            "rotation": {
              "x": 1,
              "y": 0,
              "z": 0
            },
            "configOptions": {
              "plaatsing": "links",
              "beukmaat": "3400",
              "woningdiepte": "3049",
              "daktype": "langskap"
            }
          },
          {
            "name": "Langskap Rechts",
            "houseNumber": 17,
            "type": "Type-X",
            "position": {
              "x": 11080,
              "y": 0,
              "z": 0
            },
            "rotation": {
              "x": 1,
              "y": 0,
              "z": 0
            },
            "configOptions": {
              "plaatsing": "rechts",
              "beukmaat": "1200",
              "woningdiepte": "7638",
              "daktype": "langskap"
            }
          }
        ]
      }
    ]
  }
};
