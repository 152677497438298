export const data = [
    {
        id: "ASRR-1",
        referenceId: null,
        created: "2020-01-01 09:02:46",
        finished: "2020-01-01 09:07:16",
        retried: "2020-01-01 09:02:46",
        projectName: "ASRR-TEST",
        type: "project",
        exportRevitFile: false,
        source: "ASRR",
        duration: 306,
        exports: [],
        thrownConfigurationException: false,
        thrownUploadException: false,
        skipped: false,
        skipMessage: null
    },
    {
        id: "ASRR-2",
        referenceId: null,
        created: "2020-01-01 09:02:46",
        finished: "2020-01-01 09:07:16",
        retried: "2020-01-01 09:02:46",
        projectName: "ASRR-TEST",
        type: "project",
        exportRevitFile: false,
        source: "ASRR",
        duration: 102,
        exports: [],
        thrownConfigurationException: false,
        thrownUploadException: false,
        skipped: false,
        skipMessage: null
    },
    {
        id: "ASRR-3",
        referenceId: null,
        created: "2020-01-01 09:02:46",
        finished: "2020-01-01 09:07:16",
        retried: "2020-01-01 09:02:46",
        projectName: "ASRR-TEST",
        type: "project",
        exportRevitFile: false,
        source: "ASRR",
        duration: 453,
        exports: [],
        thrownConfigurationException: true,
        thrownUploadException: true,
        skipped: false,
        skipMessage: null
    },
    {
        id: "ASRR-4",
        referenceId: null,
        created: "2020-01-01 09:02:46",
        finished: "2020-01-01 09:07:16",
        retried: "2020-01-01 09:02:46",
        projectName: "ASRR-TEST",
        type: "project",
        exportRevitFile: false,
        source: "ASRR",
        duration: 246,
        exports: [],
        thrownConfigurationException: true,
        thrownUploadException: false,
        skipped: false,
        skipMessage: null
    },
    {
        id: "ASRR-5",
        referenceId: null,
        created: "2020-01-01 09:02:46",
        finished: "2020-01-01 09:07:16",
        retried: "2020-01-01 09:02:46",
        projectName: "ASRR-TEST",
        type: "project",
        exportRevitFile: false,
        source: "ASRR",
        duration: 753,
        exports: [],
        thrownConfigurationException: false,
        thrownUploadException: false,
        skipped: false,
        skipMessage: null
    },
    {
        id: "ASRR-6",
        referenceId: null,
        created: "2020-01-01 09:02:46",
        finished: "2020-01-01 09:07:16",
        retried: "2020-01-01 09:02:46",
        projectName: "ASRR-TEST",
        type: "project",
        exportRevitFile: false,
        source: "ASRR",
        duration: 402,
        exports: [],
        thrownConfigurationException: false,
        thrownUploadException: false,
        skipped: true,
        skipMessage: null
    },
    {
        id: "ASRR-7",
        referenceId: null,
        created: "2020-01-01 09:02:46",
        finished: "2020-01-01 09:07:16",
        retried: "2020-01-01 09:02:46",
        projectName: "ASRR-TEST",
        type: "project",
        exportRevitFile: false,
        source: "ASRR",
        duration: 356,
        exports: [],
        thrownConfigurationException: false,
        thrownUploadException: false,
        skipped: false,
        skipMessage: null
    }
];

export function setSkipped(value, i) {
    data[i].skipped = value;
    console.log(data[i].skipped);

}