import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import './App.scss';
import Import from "./Components/Import";
import Footer from "./Components/Footer";
import Dashboard2 from "./Components/Dashboard2";
import {Container, Image, Menu,} from 'semantic-ui-react'
import logo from "./Images/asrr.svg"

function DashboardChild({match}) {
	return (
		<div>
			<Dashboard2/>
		</div>
	);
}

function App() {
  return (
    <div className="App">
	    <Menu color="blue" fixed='top' inverted>
		    <Container>
			    <Menu.Item as={Link} to='/' header>
				    <Image size='tiny' src={logo} style={{ marginRight: '.5em' }} />
			    </Menu.Item>
			    <Menu.Item as={Link} to='/'>Import Configuration</Menu.Item>
			    <Menu.Item as={Link} to='/dashboard/1'>Dashboard</Menu.Item>
		    </Container>
	    </Menu>

        <div className="page-content">
        <Switch>
          <Route exact path="/" component={Import}/>
          <Route exact path="/import" component={Import}/>
          <Route path="/dashboard/" component={DashboardChild}/>
        </Switch>
        </div>
        <Footer/>
    </div>
  );
}

export default App;
